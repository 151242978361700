import React from 'react';
import danPhoto from '../../assets/dan-burrell.jpg';
import youngDan from '../../assets/IMG_0407.jpg';

export const AboutText = () => (
  <>
    The Wheel of Purpose project is designed to help you improve your life by focusing on six key areas: relationships, work, spirit, play, health, and self-knowledge. Founder and creator Dan Burrell established this project in 2019 as an extension of a life that includes a stint as an actor, soldier, writer, athlete, and entrepreneur.
    <br /> <br />
    The Wheel of Purpose project arose from an essential rule Dan followed throughout this life: treat and move your feet. Translated out of showbiz lingo, “treat” means “dream.” Imagine where you want to be next. Dream big. Then, move your feet and walk in the direction of that dream. It always starts small—a tentative email or phone call to a contact you’ve been meaning to reach. Or spending five minutes a day learning a new language. Or picking up the guitar. But the thing is: those small steps turn into bigger steps until one day you’ll find yourself sitting exactly where once you only dreamed you’d be. If it sounds too good to be true, it isn’t. Just ask Dan, and spin the wheel.
  </>
)

export const MeetDanBurrell = {
  title: `Meet Dan Burrell`,
  firstP: `Dan Burrell was born and raised on the Jersey Shore in New Jersey, with his seven brothers and sisters. At seventeen, he entered the US Army and joined the 101st Airborne at Fort Campbell, Kentucky. A distinguished soldier, Dan completed air assault school, travelled to Germany for the War Games, and graduated from Jungle School in Ft. Lewis, Panama.`,
  secondP: (
    <>
      After a brief spell in Rockville community college, Dan zig-zagged his way to California with the intention of becoming an actor; he successfully worked on numerous television shows and earned his SAG card, while working in the restaurant business to support himself. He grew into management positions until he partnered with Rick Caruso and built, opened, and oversaw California Pizza Kitchen in Ventura. He then proceeded to open over 150 Jersey Mike’s franchises throughout Los Angeles.
      <br/> <br/>
      Dan became the executive director of the Ojai Valley Youth Foundation and co-created “Now the Teens Talk Will You Listen,” a process centered around facilitating communication between parents and their teenage children. Dan is currently the Chair of the St. Johns and Pleasant Valley Hospital Foundation, which supports the growth and ever-changing needs of the hospitals.
      <br/> <br/>
      Dan is now the founder of the Wheel of Purpose, created to support and assist those in search of a clearer understanding of which direction they need to move to connect to themselves and to their greater purpose. Dan has used his extensive and varied experiences and personal, physical and professional challenges to understand the connection we all have in creating our own magic and realizing our potential.
    </>
  ),
  image: youngDan,
  secondImage: danPhoto
}

