import React from 'react';

import { AboutText, MeetDanBurrell } from '../content/about';
import HeaderMargin from '../components/HeaderMargin';
import Layout from '../components/layout';
import LearnMoreFull from '../components/LearnMoreFull';
import LearnMoreSmall from '../components/LearnMoreSmall';
import InfoPicBlock from '../components/InfoPicBlock';
import QuoteBlock from '../components/QuoteBlock';
import SEO from '../components/seo';
import Video from '../components/Video';

export default () => (
  <Layout>
    <SEO title="About" />
    <Video src={'https://player.vimeo.com/video/427448354?title=0&byline=0&portrait=0'}/>
    <QuoteBlock 
      grayText={"there are six parts to the "}
      redText={"wheel of purpose"}
      longboi
    >
      <AboutText />
    </QuoteBlock>
    <LearnMoreFull />
    <InfoPicBlock info={MeetDanBurrell}/>
    <LearnMoreSmall />
  </Layout>
);
